import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Typography } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import useModalExtraWorker from 'components/Subscription/Atoms/ExtraWorkersPayment/useModalExtraWorker'
import useSubscription from 'components/Subscription/Atoms/useSubscription'
import OrganizationWhatsApp from 'components/UI/OrganizationWhatsApp'

import auth from 'utils/auth'
import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'
import useNotifications from 'utils/hooks/useNotifications'

import { SUBSCRIPTION } from 'config/routes'

import AlertCountdown from './Atoms/AlertCountdown'
import usePremiumFeature from './usePremiumFeature'

export const usePremiumActions = () => {
  const [isOpenModal, setIsOpenModal] = useState(true)
  const { refreshCompany } = useUser()
  const { showSuccessMessage } = useNotifications()

  const { subscription } = useSubscription()
  const { openPremiumFeatureModal } = usePremiumFeature()
  const modalExtraWorker = useModalExtraWorker()

  const { subscriptionMutation } = useSubscriptionService({
    queryOptions: { enabled: false },
  })

  const handleActivatePremiumTrial = (callback) => {
    subscriptionMutation.mutate(
      {
        mutationMethod: 'PUT',
        mutationKey: 'updateSubscription',
      },
      {
        onSuccess: () => {
          // Refresh for update the status page
          refreshCompany()

          if (subscription.utm_campaign !== 'Aleluya10mil') {
            showSuccessMessage(
              '¡Aleluya! Has activado exitosamente tu prueba gratis.'
            )
          }
          setIsOpenModal(false)
          if (callback) callback()
        },
      }
    )
    setIsOpenModal(true)
  }

  const handleGetPremium = () => {
    openPremiumFeatureModal()
  }

  const handlePayExtraWorkers = () => {
    modalExtraWorker.openModal()
  }

  const handlePremiumForPartnerCompany = (callback) => {
    subscriptionMutation.mutate(
      {
        mutationMethod: 'PUT',
        mutationKey: 'updateSubscription',
      },
      {
        onSuccess: () => {
          // Refresh for update the status page
          refreshCompany()

          showSuccessMessage('Has activado tu plan Premium exitosamente')
          setIsOpenModal(false)
          if (callback) callback()
        },
      }
    )
  }

  return {
    handleActivatePremiumTrial,
    handleGetPremium,
    handlePremiumForPartnerCompany,
    handlePayExtraWorkers,
    subscriptionMutation,
    isOpenModal,
  }
}

export const getStatusMessages = ({
  status,
  activeUntilFormated,
  isInSubscriptionStatusView,
  isAPartnerChild,
  paymentStatus,
  companyAccess,
}) => {
  const LinkToContactSales = () => <OrganizationWhatsApp />

  const subscriptionAlertInfoAtSubsView = {
    premium_expired: {
      message: (
        <>
          Tu plan venció el {activeUntilFormated}. Renueva tu suscripción ahora,
          para acceder nuevamente a todas las funcionalidades que Aleluya tiene
          para ti.
        </>
      ),
      severity: 'error',
    },
    premium_canceled: {
      message: (
        <>
          Tu plan venció el {activeUntilFormated}. Renueva tu suscripción ahora,
          para acceder nuevamente a todas las funcionalidades que Aleluya tiene
          para ti.
        </>
      ),
      severity: 'error',
    },
  }

  const innpulsaSubscriptionAlertInfo = {
    message: (
      <>
        Tu plan subsidiado termina el {activeUntilFormated}.{' '}
        <LinkToContactSales copy="Escríbenos ahora" /> para conservar todas tus
        funcionalidades Premium!
      </>
    ),
    severity: 'info',
  }

  const partnerChildAlertInfo = {
    message: (
      <>
        Has perdido los superpoderes incluidos en tu plan. Comunícate con
        soporte o con el encargado del pago de tu plan para poder recuperarlos.
      </>
    ),
    severity: 'error',
  }

  const pendingPayment = {
    message: (
      <>
        Tu pago está pendiente de aprobación. ¡Relax! Puedes seguir disfrutando
        de las funcionalidades que Aleluya tiene para ti.
      </>
    ),
    severity: 'warning',
  }

  const showPartnerChildAlertInfo =
    ['premium_expired', 'premium_canceled'].includes(status) &&
    !['premium', 'premium_hr', 'pro'].includes(companyAccess)

  let { message, severity } = {}

  if (paymentStatus === 'pending') {
    message = pendingPayment.message || null
    severity = pendingPayment.severity || null
  } else if (isInSubscriptionStatusView) {
    message = subscriptionAlertInfoAtSubsView[status]?.message || null
    severity = subscriptionAlertInfoAtSubsView[status]?.severity || null
  } else if (isAPartnerChild) {
    message = showPartnerChildAlertInfo ? partnerChildAlertInfo.message : null
    severity = showPartnerChildAlertInfo ? partnerChildAlertInfo.severity : null
  } else if (auth.isInnpulsaDist()) {
    message = innpulsaSubscriptionAlertInfo.message || null
    severity = innpulsaSubscriptionAlertInfo.severity || null
  }

  return { message, severity }
}

export const handlePartnerChildSubscriptionUpdate = (
  confirm,
  subscriptionMutation,
  refreshCompany,
  showSuccessMessage
) => {
  const partnerChildSubscriptionUpdate = () => {
    subscriptionMutation.mutate(
      {
        mutationMethod: 'PUT',
        mutationKey: 'updateSubscription',
        isAPartnerChild: true,
      },
      {
        onSuccess: async () => {
          await refreshCompany()
          showSuccessMessage('¡Tus superpoderes han sido activados!')
        },
      }
    )
  }

  confirm({
    okText: 'Activar superpoderes',
    type: 'warning',
    title: '¿Estás seguro de que quieres activar Aleluya Premium?',
    onOk: () => partnerChildSubscriptionUpdate(),
  })
}

export const useButtonConfig = (status) => {
  const navigate = useNavigate()
  const { openPremiumFeatureModal } = usePremiumFeature()

  if (['premium_expired', 'premium_canceled'].includes(status)) {
    return {
      label: 'Renovar suscripción',
      action: () => navigate(SUBSCRIPTION()),
    }
  }

  return {
    label: 'Activar plan premium',
    action: () => openPremiumFeatureModal(),
  }
}

export const getSubscriptionAlertCopy = ({
  status,
  premium_trial_end_date: premiumTrialEndDate,
}) => {
  if (status === 'premium_trial')
    return (
      <>
        <Typography variant="body2">Tu plan de prueba se acaba en</Typography>
        <AlertCountdown deadline={premiumTrialEndDate} />
      </>
    )

  if (status === 'premium_due')
    return (
      <Typography variant="body2">Tu plan de prueba ha finalizado</Typography>
    )

  return 'Tu plan está vencido'
}
