import { Box } from '@mui/material'

import TotalCard from 'components/UI/Card/TotalCard'

import { isArusDist } from 'utils/auth'
import { formatCurrency } from 'utils/format'

import { getPeriodTotalsConfiguration, usePeriod } from './helpers'

const PeriodTotals = ({ stack = false, compact = false, currentView }) => {
  const {
    period: {
      company_cost: companyCost,
      workers_payment: workersPayment,
      social_benefits_provision: socialBenefitsProvision,
      social_security_payment: socialSecurityPayment,
      initial_day: periodInitialDay,
    },
  } = usePeriod()
  const isArusDistributor = isArusDist()

  const totals = {
    workers_payment: workersPayment,
    company_cost: companyCost,
    social_security_payment: socialSecurityPayment,
    social_benefits_provision: socialBenefitsProvision,
  }

  const isSocialSecurityPaymentZero = socialSecurityPayment === 0

  const periodTotalsConfiguration =
    getPeriodTotalsConfiguration(periodInitialDay)

  return (
    <Box
      sx={(theme) => ({
        ...(stack
          ? {
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(3),
              ...(isSocialSecurityPaymentZero && {
                justifyContent: 'space-between',
                height: '90%',
              }),
            }
          : {
              display: 'grid',
              gridColumn: '1 / -1',
              columnGap: theme.spacing(3),
              gridTemplateColumns: 'repeat(4, 1fr)',
              gridAutoRows: 'max-content',
              marginBottom: theme.spacing(8),
              rowGap: theme.spacing(3),
              [theme.breakpoints.up('tablet')]: {
                gridTemplateColumns: 'repeat(6, 1fr)',
                columnGap: theme.spacing(4),
              },
              [theme.breakpoints.up('desktop')]: {
                gridTemplateColumns: 'repeat(12, 1fr)',
              },
            }),
      })}
    >
      {Object.entries(totals).map(([totalKey, totalValue]) => {
        if (
          totalKey === 'social_security_payment' &&
          isSocialSecurityPaymentZero
        ) {
          return null
        }

        return (
          <Box
            key={totalKey}
            sx={(theme) => ({
              gridColumn: '1 / -1',
              [theme.breakpoints.up('tablet')]: {
                gridColumn: isSocialSecurityPaymentZero ? 'span 4' : 'span 3',
              },
            })}
          >
            <TotalCard
              title={periodTotalsConfiguration[totalKey]?.title}
              icon={periodTotalsConfiguration[totalKey]?.icon}
              value={formatCurrency(totalValue || 0)}
              backgroundColor={
                periodTotalsConfiguration[totalKey]?.backgroundColor
              }
              compact={compact}
              currentView={currentView}
              isWhiteText={isArusDistributor && totalKey === 'workers_payment'}
              tooltip
              tooltipTitle={periodTotalsConfiguration[totalKey]?.tooltipTitle}
              tooltipDescription={
                periodTotalsConfiguration[totalKey]?.tooltipDescription
              }
            />
          </Box>
        )
      })}
    </Box>
  )
}

export default PeriodTotals
