import { Box } from '@mui/material'

import anniversaryState from 'assets/images/views/dashboard/anniversary_state.png'
import birthdayState from 'assets/images/views/dashboard/birthday_state.png'
import contractState from 'assets/images/views/dashboard/contract_state.png'
import explorer from 'assets/images/views/dashboard/explorer.svg'
import gift from 'assets/images/views/dashboard/gift.svg'
import trophy from 'assets/images/views/dashboard/trophy.svg'

export const getDataConfig = (type, sizeData) => {
  const defaultCopies = {
    birthdays: {
      shortTitle: 'Cumpleaños',
      title: 'Personas que cumplen años',
      description: 'La siguientes personas cumplen años:',
      icon: <Box component="img" src={gift} sx={{ width: '1.5rem' }} />,
    },
    anniversaries: {
      shortTitle: 'Aniversarios',
      title: 'Aniversarios',
      description:
        'Las siguientes personas cumplen años trabajando en tu compañía:',
      icon: <Box component="img" src={trophy} sx={{ width: '1.5rem' }} />,
    },
    contracts_to_expire: {
      shortTitle: 'Contratos a vencer',
      title: 'Contratos próximos a vencer',
      description:
        'Las siguientes personas tienen contratos próximos a vencer:',
      icon: <Box component="img" src={explorer} sx={{ width: '1.5rem' }} />,
    },
  }

  const emptyStateCopies = {
    birthdays: {
      shortTitle: 'Cumpleaños',
      title: 'Cumpleaños',
      description: 'Este mes no tienes cumpleaños que celebrar.',
      image: birthdayState,
      icon: <Box component="img" src={gift} sx={{ width: '1.5rem' }} />,
    },
    anniversaries: {
      title: 'Aniversarios',
      shortTitle: 'Aniversarios',
      description: 'Este mes no tienes aniversarios que celebrar.',
      image: anniversaryState,
      icon: <Box component="img" src={trophy} sx={{ width: '1.5rem' }} />,
    },
    contracts_to_expire: {
      shortTitle: 'Contratos a vencer',
      title: 'Contratos próximos a vencer',
      description: 'Este mes no hay contratos próximos a vencer.',
      image: contractState,
      icon: <Box component="img" src={explorer} sx={{ width: '1.5rem' }} />,
    },
  }
  return sizeData > 0 ? defaultCopies[type] : emptyStateCopies[type]
}

export default { getDataConfig }
