import { useNavigate } from 'react-router-dom'

import { Box, Button, Chip, Divider, Paper, Typography } from '@mui/material'

import useModals from 'components/App/ModalsManager/useModals'
import useSubscription from 'components/Subscription/Atoms/useSubscription'
import Adornment from 'components/UI/Adornment'

import { isPremiumExpiredByCancellationCompany } from 'utils/auth'

import { SUBSCRIPTION } from 'config/routes'

import SubscriptionConfirmationModal from './SubscriptionConfirmationModal'
import { getModalInfo } from './SubscriptionConfirmationModal/helpers'

const SubscriptionCardsModalContent = ({
  baseValue,
  cardsStyles,
  codedName,
  isAFreeCompany,
  selectedModality,
  planData,
  workerValue,
  selectedPlanIndex,
  currentPlanIndex,
  currentPlanModality,
  plansData,
  isPlanBaseAliados,
}) => {
  const modals = useModals()
  const { subscription } = useSubscription()
  const navigate = useNavigate()
  const { current_plan: isCurrentPlan, name } = planData
  const planUpgrade = selectedPlanIndex > currentPlanIndex
  const isChangingModality = currentPlanModality !== selectedModality
  const modalityUpgrade = isChangingModality && currentPlanModality === 'month'

  const {
    active_until: activeUntil,
    payrolls_size: currentWorkersNumber,
    status,
  } = subscription || {}

  const isPremiumExpiredSubscription =
    status === 'premium_expired' ||
    isPremiumExpiredByCancellationCompany(subscription)

  const isPremiumHRPlan = codedName === 'premium_hr_per_worker_plan'

  const navigateTosubscription = () => {
    const state = {
      modality: selectedModality,
      selectedPlan: planData,
      planOptions: plansData,
    }

    navigate(SUBSCRIPTION(), { state })
  }

  const isAnUpgrade =
    planUpgrade || (modalityUpgrade && (planUpgrade || isCurrentPlan))

  const currentPlanPayment = isCurrentPlan && !isChangingModality

  const buttonText = () => {
    if (isAnUpgrade) return 'Mejorar mi plan'
    if (currentPlanPayment) return 'Pagar mi plan'
    return 'Cambiar mi plan'
  }

  const {
    adminDescription,
    chipBackgroundColor,
    color,
    description,
    icon,
    wrapperColor,
  } = cardsStyles

  const { title } = getModalInfo(
    currentPlanPayment,
    modalityUpgrade,
    planUpgrade,
    subscription,
    isChangingModality,
    isPremiumExpiredSubscription,
    activeUntil
  )

  const getImmediatePayment =
    isAnUpgrade || isPremiumExpiredSubscription || currentPlanPayment

  const showExtraWorkersCounter =
    selectedModality === 'year' && getImmediatePayment

  const handleContinuePayment = () => {
    if (isAFreeCompany) {
      modals.closeAll()
      navigateTosubscription()
    } else {
      modals.closeModal('subscriptionCardsModal')
      modals.openModal({
        id: 'subscriptionConfirmationModal',
        content: (
          <SubscriptionConfirmationModal
            selectedPlan={planData}
            selectedModality={selectedModality}
            isAnUpgrade={isAnUpgrade}
            currentPlanPayment={currentPlanPayment}
            activeUntil={activeUntil}
            planUpgrade={planUpgrade}
            modalityUpgrade={modalityUpgrade}
            currentWorkersNumber={currentWorkersNumber}
            isChangingModality={isChangingModality}
            isCurrentPlan={isCurrentPlan}
            isPremiumExpiredSubscription={isPremiumExpiredSubscription}
          />
        ),
        modalProps: {
          header: title,
          hideFooter: true,
          adornment: !showExtraWorkersCounter
            ? [
                {
                  variant: 'signature',
                  color: 'accent4.light',
                  width: 165,
                  height: 182,
                  sx: {
                    bottom: 85,
                    right: 75,
                  },
                },
              ]
            : null,
          paperSx: {
            maxWidth: '45rem',
            width: '100%',
          },
        },
      })
    }
  }

  return !isPlanBaseAliados ? (
    <Box
      sx={(theme) => ({
        position: 'relative',
        alignSelf: isCurrentPlan ? 'flex-end' : 'center',
        justifySelf: 'center',
        height: isCurrentPlan && !isPremiumHRPlan ? '28.5rem' : '30.5rem',
        backgroundColor:
          isCurrentPlan || isPremiumHRPlan ? wrapperColor : 'none',
        borderBottomLeftRadius: '1.25rem',
        borderBottomRightRadius: '1.25rem',
        [theme.breakpoints.up('sm')]: {
          minWidth: '16rem',
          maxWidth: '16rem',
        },
        ...(isPremiumHRPlan && { height: '31rem' }),
        [theme.breakpoints.down('sm')]: {
          ...((isPremiumHRPlan || isCurrentPlan) && {
            marginTop: theme.spacing(5),
          }),
        },
      })}
      data-cy={`${codedName}_card`}
    >
      {isCurrentPlan || isPremiumHRPlan ? (
        <Box
          sx={(theme) => ({
            borderTopRightRadius: '1.25rem',
            borderTopLeftRadius: '1.25rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: wrapperColor,
            columnGap: theme.spacing(0.5),
            position: 'absolute',
            width: '100%',
            top: '-7%',
            height: '2.3rem',
          })}
        >
          {isPremiumHRPlan ? (
            <Adornment
              variant="explosion"
              height={22}
              width={24}
              color="black.main"
              sx={{
                position: 'initial',
                height: 'min-content',
                alignSelf: 'center',
              }}
            />
          ) : null}
          <Typography variant="lead1">
            {isCurrentPlan ? 'Plan actual' : 'Recomendado'}
          </Typography>
        </Box>
      ) : null}
      <Paper
        sx={(theme) => ({
          borderRadius: '1.25rem',
          boxShadow: theme.shadows[7],
          minWidth: '16rem',
          padding:
            isCurrentPlan && !isPremiumHRPlan
              ? theme.spacing(1, 1)
              : theme.spacing(2, 1),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifySelf: 'center',
          height: '100%',
          border:
            isCurrentPlan || isPremiumHRPlan
              ? `0.25rem solid ${wrapperColor}`
              : 'none',
          [theme.breakpoints.up('sm')]: {
            minWidth: '16rem',
            maxWidth: '16rem',
          },
          ...(isPremiumHRPlan && { paddingTop: theme.spacing(2) }),
        })}
      >
        <Box
          sx={(theme) => ({
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            marginBottom: theme.spacing(3.5),
          })}
        >
          <Typography
            color={color}
            variant="h4"
            sx={(theme) => ({
              marginRight: theme.spacing(0.5),
            })}
          >
            {name}
          </Typography>
          {icon}
        </Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            rowGap: theme.spacing(7),
            minHeight: '12rem',
          })}
        >
          <Typography
            variant="body2"
            color="black.dark"
            sx={(theme) => ({
              position: 'absolute',
              top: isCurrentPlan && !isPremiumHRPlan ? 60 : 92,
              left: 0,
              width: '100%',
              padding:
                isPremiumHRPlan || isCurrentPlan
                  ? theme.spacing(0, 1.5)
                  : theme.spacing(0, 1),
              ...(isPremiumHRPlan && { top: 95.5 }),
              [theme.breakpoints.up('sm')]: {
                ...(isCurrentPlan && !isPremiumHRPlan && { top: 57 }),
              },
            })}
          >
            {description}
          </Typography>
          <Typography
            variant="small"
            color="black.main"
            sx={(theme) => ({
              position: 'absolute',
              top: isCurrentPlan && !isPremiumHRPlan ? 168 : 200,
              left: 0,
              padding:
                isPremiumHRPlan || isCurrentPlan
                  ? theme.spacing(0, 1.5)
                  : theme.spacing(0, 1),
              ...(isPremiumHRPlan && { top: 204 }),
              [theme.breakpoints.up('sm')]: {
                ...(isCurrentPlan && !isPremiumHRPlan && { top: 165 }),
              },
            })}
          >
            <b>Usuarios administradores:</b>{' '}
            <Typography variant="small" color="black.dark">
              {adminDescription}
            </Typography>
          </Typography>
        </Box>
        <Divider
          sx={(theme) => ({
            position: 'absolute',
            top: isCurrentPlan && !isPremiumHRPlan ? 213 : 244.5,
            width: isPremiumHRPlan || isCurrentPlan ? '92%' : '95%',
            height: 0,
            margin: theme.spacing(4, 0, 3),
            border: `1px dashed ${theme.palette.black.light}`,
            ...(isPremiumHRPlan && { top: 248.5 }),
            [theme.breakpoints.up('sm')]: {
              ...(isCurrentPlan && !isPremiumHRPlan && { top: 209 }),
            },
          })}
        />
        <Box
          sx={(theme) => ({
            width: '100%',
            position: 'absolute',
            top: isCurrentPlan && !isPremiumHRPlan ? 268 : 300,
            padding: theme.spacing(0, 2),
            ...(isPremiumHRPlan && { top: 304 }),
            [theme.breakpoints.up('sm')]: {
              ...(isCurrentPlan && !isPremiumHRPlan && { top: 265 }),
            },
          })}
        >
          <Typography variant="h4" color={color}>
            {`${baseValue} / Mes`}
          </Typography>
          <Chip
            label={`+ ${workerValue} / Persona`}
            sx={(theme) => ({
              backgroundColor: chipBackgroundColor,
              height: '2rem',
              width: '80%',
              borderRadius: '1rem',
              '& .MuiChip-label': {
                color,
                fontWeight: 'bold',
              },
              margin: theme.spacing(2, 0, 3),
            })}
          />
          {!currentPlanPayment ? (
            <Button
              fullWidth
              onClick={handleContinuePayment}
              variant={!isPremiumHRPlan ? 'outlined' : 'contained'}
              data-cy={`${codedName}_button`}
            >
              {buttonText()}
            </Button>
          ) : null}
        </Box>
      </Paper>
    </Box>
  ) : (
    <Paper
      sx={(theme) => ({
        borderRadius: '1.25rem',
        boxShadow: theme.shadows[7],
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      })}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography
          color={color}
          variant="h4"
          sx={(theme) => ({
            padding: theme.spacing(2, 2, 0, 2),
          })}
        >
          {name}
        </Typography>
        <Typography
          variant="body2"
          color="black.dark"
          sx={(theme) => ({
            padding: theme.spacing(2),
          })}
        >
          Con tu plan{' '}
          <Box component="span" color="black.main" fontWeight={700}>
            Aliado
          </Box>{' '}
          podrás automatizar los procesos de nómina de todos tus clientes en un
          mismo lugar. Además, tendrás acceso a soporte prioritario y mucho más.
        </Typography>
        {icon}
      </Box>
      <Box
        sx={(theme) => ({
          width: '100%',
          padding: theme.spacing(0, 2),
          margin: theme.spacing(3, 0),
        })}
      >
        <Button
          fullWidth
          onClick={handleContinuePayment}
          data-cy={`${codedName}_button`}
        >
          {buttonText()}
        </Button>
      </Box>
    </Paper>
  )
}

export default SubscriptionCardsModalContent
