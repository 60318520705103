import {
  Box,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useMediaQuery,
} from '@mui/material'

import Adornment from '../Adornment'
import Button from '../Button/Button'
import Icon from '../Icon'
import Pattern from '../Pattern'

const adornments = {
  asterisk: {
    width: 105,
    height: 105,
    color: 'complementary2.light',
  },
  explosion: {
    width: 202,
    height: 183,
    color: 'primary.light',
  },
  signature: {
    width: 219,
    height: 242,
    color: 'accent3.light',
  },
  spring: {
    width: 196,
    height: 86,
    color: 'accent2.light',
  },
}

const Modal = ({
  open = true,
  header,
  footer,
  hideFooter = false,
  children,
  onOk,
  okText = 'OK',
  disableOkButton = false,
  isLoading = false,
  loadingText = 'Cargando...',
  onCancel,
  onCloseModal = null, // Only when X button behaviour is different to cancel Button
  cancelText = 'Cancelar',
  hideCloseButton = false,
  hideCancelButton = false,
  hideOkButton = false,
  fullScreenBreakpoint = 'tablet',
  alignButtons = 'left',
  dialogProps = {},
  disableBackdropClick = false,
  disableEscapeKeyDown = false,
  adornment = null,
  dialogContentSx = {},
  paperSx = {},
  patternBackgroundColor = 'complementary1.dark',
  patternColor = 'black',
  dataCy,
  contentWrapperSx = {},
}) => {
  const fullScreen = useMediaQuery((theme) =>
    theme.breakpoints.down(fullScreenBreakpoint)
  )
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))
  const withTopPattern = adornment === 'pattern'
  const withBottomPattern = adornment === 'pattern-bottom'

  const handleClose = (_, reason) => {
    if (reason === 'backdropClick' && !disableBackdropClick && !isLoading) {
      if (onCancel) {
        onCancel()
      }
      return
    }

    if (reason === 'escapeKeyDown' && !disableEscapeKeyDown && !isLoading) {
      if (onCancel) {
        onCancel()
      }
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      aria-labelledby="dialog-title"
      {...dialogProps}
      scroll="body"
      PaperProps={{
        sx: [
          (theme) => ({
            overflow: 'hidden',
            position: 'relative',
            zIndex: 1,
            boxShadow: theme.shadows[5],
            borderRadius: !isMobile ? '1rem' : 0,
          }),
          paperSx,
        ],
        elevation: 0,
        'data-cy': dataCy,
      }}
    >
      <Box
        id="modal-content-wrapper"
        sx={[
          (theme) => ({
            overflow: 'auto',
            height: '100%',
            zIndex: 2,
            position: 'relative',
            padding: theme.spacing(3, 1.5, 6, 1.5),
            [theme.breakpoints.up('tablet')]: {
              padding: theme.spacing(3, 3, 5, 3),
              ...(withTopPattern && {
                paddingTop: 6,
              }),
              ...(withBottomPattern && {
                paddingBottom: 6,
              }),
            },
            [theme.breakpoints.up('laptop')]: {
              padding: theme.spacing(4, 4, 5, 4),
              ...(withTopPattern && {
                paddingTop: 7,
              }),
              ...(withBottomPattern && {
                paddingBottom: 7,
              }),
            },
          }),
          contentWrapperSx,
        ]}
      >
        {header ? (
          <DialogTitle
            id="dialog-title"
            component="div"
            sx={(theme) => ({
              padding: theme.spacing(0),
              marginBottom: theme.spacing(2.5),
              marginTop: 0,
              display: 'grid',
              gridTemplateColumns: '1fr auto',
              columnGap: theme.spacing(3.5),
              alignItems: 'start',
            })}
          >
            {typeof header === 'string' ? (
              <Typography variant="h3">{header}</Typography>
            ) : (
              header
            )}
            {(onCancel || onCloseModal) && !hideCloseButton ? (
              <ButtonBase
                onClick={onCloseModal || onCancel}
                disabled={isLoading}
                data-cy="close-dialog-button"
                sx={(theme) => ({
                  cursor: 'pointer',
                  color: theme.palette.black.main,
                })}
              >
                <Icon name="close" basic sx={{ fontSize: '1.7rem' }} />
              </ButtonBase>
            ) : null}
          </DialogTitle>
        ) : null}
        <DialogContent
          sx={[
            {
              padding: 0,
              zIndex: 2,
              overflow: 'visible',
              position: 'relative',
            },
            dialogContentSx,
          ]}
        >
          {typeof children === 'string' ? (
            <DialogContentText>{children}</DialogContentText>
          ) : (
            children
          )}
        </DialogContent>
        {!hideFooter && footer !== null ? (
          <DialogActions
            sx={(theme) => ({
              padding: theme.spacing(0),
              marginTop: theme.spacing(6),
              justifyContent: 'flex-start',
              zIndex: 2,
              position: 'inherit',
            })}
          >
            {footer || (
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  width: '100%',
                  gap: theme.spacing(2),
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  [theme.breakpoints.up('tablet')]: {
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                  },
                  ...(alignButtons === 'right' && {
                    justifyContent: 'flex-end',
                  }),
                  ...(alignButtons === 'center' && {
                    justifyContent: 'center',
                  }),
                  ...((hideCancelButton || hideOkButton) &&
                    alignButtons === 'center' && {
                      justifyContent: 'center',
                    }),
                })}
              >
                {!hideOkButton ? (
                  <Button
                    onClick={onOk}
                    disabled={isLoading || disableOkButton}
                    loading={isLoading}
                    data-cy="modal_ok_button"
                  >
                    {isLoading ? loadingText : okText}
                  </Button>
                ) : null}
                {!hideCancelButton ? (
                  <Button
                    onClick={onCancel}
                    variant="outlined"
                    disabled={isLoading}
                    data-cy="modal_cancel_button"
                    sx={(theme) => ({
                      backgroundColor: theme.palette.white.main,
                    })}
                  >
                    {cancelText}
                  </Button>
                ) : null}
              </Box>
            )}
          </DialogActions>
        ) : null}
      </Box>
      {adornment &&
      typeof adornment === 'string' &&
      adornments[adornment] &&
      !isMobile ? (
        <Adornment
          variant={adornment}
          {...adornments[adornment]}
          sx={{
            bottom: '1.25rem',
            right: '2.125rem',
            zIndex: 1,
          }}
        />
      ) : null}
      {adornment &&
      !(typeof adornment === 'string') &&
      Array.isArray(adornment) &&
      !isMobile ? (
        <>
          {adornment.map((adornmentItem, index) => (
            <Adornment
              key={index.toString()}
              variant={adornmentItem.variant}
              width={adornmentItem.width}
              height={adornmentItem.height}
              color={adornmentItem.color}
              sx={adornmentItem.sx}
            />
          ))}
        </>
      ) : null}
      {adornment && adornment.includes('pattern') && !isMobile ? (
        <Pattern
          color={patternBackgroundColor}
          patternColor={patternColor}
          adornment={adornment}
        />
      ) : null}
    </Dialog>
  )
}

export default Modal
