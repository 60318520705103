export const cryEmoji = '\u{1F622}'
export const partyPopperEmoji = '\u{1F389}'
export const fatherChristmas = '\u{1F385}'
export const smilingEmoji = '\u{1F642}'
export const wrench = '\u{1F527}'
export const partyingFace = '\u{1F973}'
export const openBook = '\u{1F4D6}'
export const redHeart = '\u{FE0F}\u{2764}\u{FE0F}'
export const bowAndArrow = '\u{1F3F9}'
export const wavingHand = '\u{1F44B}'
export const sunFace = '\u{1F31E}'
export const eyes = '\u{1F440}'
export const redCrossMark = '\u{274C}'
export const technologistWoman = '\u{1F469}\u{200D}\u{1F4BB}'
export const happyFace = '\u{1f603}'
export const superHappyFace = '\u{1f604}'
export const exclamation = '\u{2753}'
export const rooster = '\u{1F413}'
export const chronometer = '\u{23f1}'
export const coffee = '\u{2615}'
export const faceHeartEyes = '\u{1F60D}'
export const starEyesFace = '\u{1f929}'
export const grimacingFace = '\u{1f62c}'
export const fire = '\u{1F525}'
export const sparkles = '\u{2728}'
export const rocket = '\u{1F680}'
export const gift = '\u{1F381}'
export const greenHeart = '\u{1f49a}'
export const highVoltage = '\u{26a1}'
export const flowers = '\u{1F490}'
export const floppyDisk = '\u{1F4BE}'
export const thinkingFace = '\u{1F914}'
export const warning = '\u{26A0}\u{FE0F}'
export const rightPointingHand = '\u{1f449}'
export const alarmClock = '\u{23f0}'
export const sunglassesFace = '\u{1f60e}'
export const wingedMoney = '\u{1f4b8}'
export const triangularRedFlag = '\u{1F6A9}'
export const magnifyingGlass = '\u{1F50E}'
export const raceCar = '\uD83C\uDFCE\uFE0F'
export const car = '\u{1F697}'
