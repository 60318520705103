export const CLAIMS_LINK =
  'https://docs.google.com/forms/d/e/1FAIpQLSdXF_cqOCrFJg4KH7SKHaQJxG0aypLRD5ytYTV6gu4aRFajuQ/viewform'

export const claimsSectionCardInfo = [
  {
    id: 'upload_disabilities',
    number: 1,
    icon: 'document-upload',
    title: 'Carga tus incapacidades',
    numberBackgroundColor: 'accent4.dark',
  },
  {
    id: 'charge_disabilities',
    number: 2,
    icon: 'refund',
    title: 'Cobramos tus incapacidades',
    numberBackgroundColor: 'accent3.main',
  },
  {
    id: 'receive_money',
    number: 3,
    icon: 'withdrawal',
    title: 'Recibe tu platica en tu cuenta bancaria',
    numberBackgroundColor: 'primary.dark',
  },
]

export const coachingSectionCardsInfo = [
  {
    id: 'schedule_coaching',
    number: 1,
    icon: 'appointment-schedule',
    title: 'Solicita una cita gratuita con tu Coach',
    numberBackgroundColor: 'accent4.dark',
  },
  {
    id: 'receive_plan',
    number: 2,
    icon: 'people-handshake',
    title: 'Recibe un plan personalizado',
    numberBackgroundColor: 'accent3.main',
  },
  {
    id: 'financial_freedom',
    number: 3,
    icon: 'yoga-female',
    title: 'Comienza a vivir una vida libre de estrés financiero',
    numberBackgroundColor: 'primary.dark',
  },
]

export const createFakeDataPie = (sizeArray) => {
  const data = []

  for (let index = 0; index < sizeArray; index++) {
    data.push({ value: 1 })
  }
  return data
}
