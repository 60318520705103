import { Typography } from '@mui/material'

import Emoji from 'components/UI/Emoji'

import {
  formatDisplayDate,
  formatLongDisplayDate,
  getColombianCurrentDate,
} from 'utils/dateTime'
import { car, fire, raceCar } from 'utils/emojis'
import { formatCurrency, getWordSingular } from 'utils/format'

const IMMEDIATE_PAYMENTE_TEXT = (
  <>
    Mira con lupa la información de tu nuevo plan, realiza el pago y vámonos de
    viaje con tus nuevos superpoderes de Aleluya.
  </>
)
const UPGRADE_TITLE = (
  <Typography variant="h3">
    Estás a un paso de cambiar un Twingo por un Ferrari!{' '}
    <Emoji code={raceCar} />
  </Typography>
)

const DOWNGRADE_TITLE = (
  <Typography
    variant="h3"
    sx={{
      width: '90%',
      justifyItems: 'center',
    }}
  >
    Vas a cambiar un Ferrari por un Twingo? <Emoji code={car} />
  </Typography>
)
const DOWNGRADE_TEXT = (
  <>
    Mira con lupa la información de tu nuevo plan. Tus superpoderes en Aleluya
    cambiarán una vez termine el periodo actual de facturación.
  </>
)

export const getModalInfo = (
  currentPlanPayment,
  modalityUpgrade,
  planUpgrade,
  subscription,
  isChangingModality,
  isPremiumExpiredSubscription,
  isPartner,
  isAPartnerChild,
  activeUntil
) => {
  const getChangeType = () => {
    if (isPremiumExpiredSubscription) return 'is_premium_expired_subscription'
    if (isAPartnerChild) {
      if (planUpgrade) return 'is_partner_child_upgrade'

      return 'is_partner_child_downgrade'
    }
    if (modalityUpgrade && planUpgrade) return 'modality_&_plan_upgrade'
    if (modalityUpgrade && !planUpgrade) return 'modality_upgrade'
    if (subscription.type === 'year' && isChangingModality && planUpgrade)
      return 'modality_downgrade_&_plan_upgrade'
    if (subscription.type === 'year' && isChangingModality)
      return 'modality_downgrade'
    if (currentPlanPayment) return 'current_plan_payment'
    if (!planUpgrade) return 'plan_downgrade'

    return 'plan_upgrade'
  }

  const modalInfo = {
    is_partner_child_upgrade: {
      title: null,
      text: (
        <>La activación de los superpoderes en tu nuevo plan será inmediata.</>
      ),
    },
    is_partner_child_downgrade: {
      title: null,
      text: (
        <>
          La actualización se hará efectiva al finalizar tu actual periodo de
          facturación, el <b>{formatLongDisplayDate(activeUntil)}.</b>
        </>
      ),
    },
    is_premium_expired_subscription: {
      title: null,
      text: (
        <>
          Con este pago, podrás seguir disfrutando de todas las funcionalidades
          que Aleluya te ofrece.
          {subscription.type === 'year' ? (
            <>
              <br />
              <br />
              Recuerda que puedes agregar más personas en tu suscripción, para
              que no se quede ninguna por fuera.
            </>
          ) : null}
        </>
      ),
    },
    current_plan_payment: {
      title: null,
      text: (
        <>
          Realizarás el pago de tu plan actual, para continuar disfrutando de
          todas las funcionalidades que Aleluya te ofrece.
          <br />
          <br />
          {subscription.type === 'year'
            ? ' Recuerda que puedes agregar más personas en tu suscripción, para que no se quede ninguna por fuera.'
            : null}
        </>
      ),
    },
    'modality_&_plan_upgrade': {
      title: UPGRADE_TITLE,
      text: IMMEDIATE_PAYMENTE_TEXT,
    },
    modality_downgrade: {
      title: DOWNGRADE_TITLE,
      text: (
        <>
          Mira con lupa la información de tu nuevo plan. Tu frecuencia de pago
          cambiará, una vez termine el periodo actual de facturación.
        </>
      ),
    },
    modality_upgrade: {
      title: (
        <Typography variant="h3">
          Estás a un paso de darle más flow a tu plan! <Emoji code={fire} />
        </Typography>
      ),
      text: IMMEDIATE_PAYMENTE_TEXT,
    },
    'modality_downgrade_&_plan_upgrade': {
      title: UPGRADE_TITLE,
      text: (
        <>
          Mira con lupa la información de tu nuevo plan. Tus superpoderes en
          Aleluya se actualizarán de inmediato, pero tu ciclo de facturación
          solo se modificará al final del periodo actual.
        </>
      ),
    },
    plan_downgrade: {
      title: DOWNGRADE_TITLE,
      text: DOWNGRADE_TEXT,
    },
    plan_upgrade: {
      title: UPGRADE_TITLE,
      text: IMMEDIATE_PAYMENTE_TEXT,
    },
  }

  return modalInfo[getChangeType()]
}

export const getCardsData = (
  totalPayment,
  activeUntil,
  themeInstance,
  currentWorkersNumber,
  planUpgrade,
  paidWorkers,
  selectedModality,
  isPremiumExpiredSubscription
) => {
  const date =
    planUpgrade || isPremiumExpiredSubscription
      ? getColombianCurrentDate()
      : activeUntil

  const data = [
    {
      id: 'total_payment',
      label: `Valor total pagar ${
        selectedModality === 'year' ? 'anualidad' : 'mensualidad'
      }`,
      value:
        typeof totalPayment === 'number' ? formatCurrency(totalPayment) : null,
      adornmentColor: 'accent3.light',
      baseColor: themeInstance.palette.accent3.main,
      footnote:
        selectedModality === 'year'
          ? '*Incluye descuento de suscripción anual'
          : null,
    },
    {
      id: 'employees_number',
      label: (
        <>
          Total personas{' '}
          {planUpgrade || isPremiumExpiredSubscription ? 'pagadas' : 'activas'}{' '}
        </>
      ),
      value: (
        <>
          {planUpgrade ? paidWorkers : currentWorkersNumber}{' '}
          {getWordSingular('personas', currentWorkersNumber === 1)}
        </>
      ),
      adornmentColor: 'primary.light',
      baseColor: themeInstance.palette.primary.dark,
    },
    {
      id: 'plan_initial_date',
      label:
        planUpgrade || isPremiumExpiredSubscription
          ? 'Fecha inicio plan'
          : 'Próxima fecha de pago',
      value: formatDisplayDate(date),
      adornmentColor: 'complementary1.light',
      baseColor: themeInstance.palette.complementary1.dark,
    },
  ]

  return data
}
