import { Formik, useFormikContext } from 'formik'
import { useRef, useState } from 'react'
import { useQueryClient } from 'react-query'

import { Box, Button, Typography } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'
import Icon from 'components/UI/Icon'
import useLoadingModal from 'components/UI/Loading/useLoadingModal'

import useBeneficiariesService from 'utils/hooks/affiliations/beneficiaries'

import Actions from './Actions'
import EditBeneficiary from './EditBeneficiary'
import {
  attachDocuments,
  remainKinshipOptions,
  validationSchema,
} from './helpers'

const initialValues = {
  files: {},
  name: '',
  kinship: '',
}

const BeneficiariesStep = ({ worker }) => {
  const queryClient = useQueryClient()
  const { showLoadingModal, hideLoadingModal } = useLoadingModal()
  const formikRef = useRef(null)
  const { values: mainFormValues } = useFormikContext()
  const [showAddBeneficiary, setShowAddBeneficiary] = useState(false)
  const affiliationQueryKey = ['getAffiliationById', worker?.id]
  const beneficiaries = mainFormValues?.beneficiaries || []

  const { beneficiariesMutation } = useBeneficiariesService()

  const onSubmit = (values, form) => {
    const data = new FormData()

    data.append('name', values.name)
    data.append('kinship', values.kinship)

    if (values.files && typeof values.files === 'object') {
      Object.entries(values.files).forEach(([key, value]) => {
        if (value instanceof File) {
          data.append(key.slice(0, -5), value)
        }
      })
    }

    showLoadingModal()

    beneficiariesMutation.mutate(
      {
        mutationMethod: 'PUT',
        workerId: worker?.id,
        beneficiaryData: data,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(affiliationQueryKey)
          setShowAddBeneficiary(false)
          hideLoadingModal()
          form.resetForm()
        },
        onError: () => {
          hideLoadingModal()
        },
      }
    )
  }

  const handleCloseAddBeneficiaryFields = () => {
    setShowAddBeneficiary(false)
    formikRef.current?.resetForm()
  }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, submitForm }) => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography variant="h6" color="primary.dark">
              Beneficiarios
            </Typography>
            <Typography variant="body1" color="black.dark">
              Los beneficiarios son personas que podrán disfrutar de los
              beneficios de seguridad social. Elige el parentezco y adjunta la
              documentación requerida.
            </Typography>
            {beneficiaries.map((beneficiary) => (
              <EditBeneficiary
                key={beneficiary.id}
                worker={worker}
                beneficiaries={beneficiaries}
                beneficiary={beneficiary}
                affiliationQueryKey={affiliationQueryKey}
              />
            ))}
            {showAddBeneficiary ? (
              <Box
                sx={(theme) => ({
                  display: 'grid',
                  columnGap: theme.spacing(3),
                  marginTop: theme.spacing(2.5),
                  gridTemplateColumns: '1fr 5rem',
                })}
              >
                <Box
                  sx={(theme) => ({
                    display: 'grid',
                    gap: theme.spacing(3),
                    gridTemplateColumns: '1fr',
                    [theme.breakpoints.up('tablet')]: {
                      gridTemplateColumns: '1fr 1fr',
                    },
                  })}
                >
                  <FormField
                    name="name"
                    label="Nombre completo"
                    optional={false}
                    placeholder="Nombre del beneficiario"
                  />
                  <FormField
                    name="kinship"
                    label="Tipo de beneficiario"
                    optional={false}
                    variant="select"
                    placeholder="Selecciona"
                    options={remainKinshipOptions(
                      beneficiaries,
                      values.kinship
                    )}
                  />
                </Box>
                <Actions
                  leftAction={{
                    tooltip: 'Confirmar',
                    icon: <Icon name="approve-checked" basic />,
                    onClick: submitForm,
                  }}
                  rightAction={{
                    tooltip: 'Cancelar',
                    icon: <Icon name="close-2" basic />,
                    onClick: handleCloseAddBeneficiaryFields,
                  }}
                />
                <Box
                  sx={(theme) => ({
                    display: 'grid',
                    gridColumn: '1 / -1',
                    gridTemplateColumns: '1fr',
                    columnGap: theme.spacing(3),
                    rowGap: theme.spacing(3),
                    marginTop: theme.spacing(3),
                    [theme.breakpoints.up('tablet')]: {
                      gridTemplateColumns: '1fr 1fr',
                      paddingRight: '6.5rem',
                    },
                  })}
                >
                  {attachDocuments({
                    kinship: values?.kinship,
                    files: values?.files,
                  })}
                </Box>
              </Box>
            ) : null}
            <Button
              endIcon={<Icon name="plus" basic />}
              variant="outlined"
              onClick={() => {
                setShowAddBeneficiary(true)
              }}
              sx={(theme) => ({
                width: 'fit-content',
                marginTop: theme.spacing(3),
              })}
            >
              Agregar beneficiario
            </Button>
          </Box>
        )
      }}
    </Formik>
  )
}

export default BeneficiariesStep
