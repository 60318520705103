import { Box, Typography } from '@mui/material'

import Icon from 'components/UI/Icon'
import Link from 'components/UI/MaterialUI/Link'
import WorkerPicture from 'components/UI/WorkerPicture'

import { getWordSingular } from 'utils/format'

import { WORKER_SHOW } from 'config/routes'

const renderAdditionalInfo = (numberYears, id) => {
  const label = `${numberYears} ${getWordSingular('años', numberYears === 1)}`

  return {
    birthdays: (
      <Icon name="birthday-cake" twoTone sx={{ fontSize: '2.5rem' }} />
    ),
    anniversaries: (
      <Typography variant="lead1" sx={{ whiteSpace: 'nowrap' }}>
        {label}
      </Typography>
    ),
    contracts_to_expire: (
      <Link
        underline="always"
        to={WORKER_SHOW(id)}
        fontWeight={700}
        color="accent4.main"
        style={{
          whiteSpace: 'nowrap',
        }}
      >
        Ver
      </Link>
    ),
  }
}

const EventItem = ({
  name,
  date,
  avatarId,
  picture,
  workerId,
  eventType,
  compact = true,
  years = null,
}) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        gap: theme.spacing(1),
      })}
    >
      <Box
        sx={(theme) => ({
          width: '100%',
          display: 'flex',
          gap: theme.spacing(1),
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        })}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <WorkerPicture
            avatarId={avatarId}
            picture={picture}
            avatarsSx={{ fontSize: '2.7rem' }}
          />
        </Box>
        <Box
          sx={{
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
            variant="lead2"
            title={name}
          >
            {name}
          </Typography>
          <Typography variant="body2" color="black.dark">
            {date}
          </Typography>
        </Box>
      </Box>
      {compact ? null : renderAdditionalInfo(years, workerId)[eventType]}
    </Box>
  )
}

export default EventItem
