import { Box, Button, Typography } from '@mui/material'

import LoadingBox from 'components/UI/Loading/LoadingBox'

import EventItem from './EventItem'
import { getDataConfig } from './helpers'

const EventCard = ({ eventType, data, isLoading, handleShowDetail }) => {
  const sizeData = data?.length

  const { title, shortTitle, description, image, icon } = getDataConfig(
    eventType,
    sizeData
  )

  const handleShowMore = () => {
    handleShowDetail({
      title,
      description,
      items: data,
      eventType,
    })
  }

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.accent2.light,
        borderRadius: '1rem',
        padding: theme.spacing(2, 3),
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        height: '100%',
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(0.5),
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        })}
      >
        <Box
          sx={(theme) => ({
            alignItems: 'center',
            columnGap: theme.spacing(0.5),
            display: 'flex',
          })}
        >
          <Typography component="span" sx={{ display: 'inline-flex' }}>
            {shortTitle}
          </Typography>
          {icon}
        </Box>
        <Button variant="text" onClick={handleShowMore}>
          Ver más
        </Button>
      </Box>
      {isLoading ? (
        <LoadingBox />
      ) : (
        <Box
          sx={{
            height: '100%',
            ...(sizeData === 0 && {
              display: 'flex',
              alignItems: 'center',
              maxHeight: '100%',
              width: '100%',
              overflow: 'hidden',
              justifyContent: 'center',
            }),
          }}
        >
          {sizeData > 0 ? (
            <Box
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(2),
                width: '100%',
              })}
            >
              {data?.slice(0, 3)?.map((item, index) => {
                const avatarId = (index % 5) + 1
                const key = `${eventType}-${index}`

                return (
                  <EventItem
                    key={key}
                    eventType={eventType}
                    name={item.worker_name}
                    date={item.date}
                    years={item.years}
                    workerId={item.workerId}
                    avatarId={avatarId}
                    picture={item.picture}
                  />
                )
              })}
            </Box>
          ) : (
            <Box
              component="img"
              src={image}
              sx={(theme) => ({
                background: theme.palette.white.main,
                width: 'auto',
                height: 'auto',
                objectFit: 'contain',
                maxHeight: '100%',
                maxWidth: '100%',
              })}
            />
          )}
        </Box>
      )}
    </Box>
  )
}

export default EventCard
