import cardValidator from 'card-validator'
import * as yup from 'yup'

import { Box } from '@mui/material'

import Icon from 'components/UI/Icon'

import { yupLocaleES } from 'utils/form'

import { getCurrentPaymentMethods } from '../Index/PaymentMethod/helpers'
import SubscriptionAutomaticDebitFields from './AutomaticDebitFields'
import SubscriptionCreditCardFields from './CreditCardFields'
import SubscriptionPSEFields from './PSEFields'

yup.setLocale(yupLocaleES)

export const getPaymentFields = (isEditingPaymentMethod = false) => ({
  automatic_debit: {
    fields: (
      <SubscriptionAutomaticDebitFields
        isEditingPaymentMethod={isEditingPaymentMethod}
      />
    ),
  },
  credit_card: {
    fields: (
      <SubscriptionCreditCardFields
        isEditingPaymentMethod={isEditingPaymentMethod}
      />
    ),
  },
  pse: {
    fields: <SubscriptionPSEFields />,
  },
  bank_transfer: {
    fields: <SubscriptionPSEFields />,
  },
})

const creditCardFormSchema = yup.object({
  payer_name: yup.string().trim().nullable().required(),
  card_number: yup
    .number()
    .nullable()
    .required()
    .test(
      'test-number',
      'Número de tarjeta de crédito inválido',
      (value) => cardValidator.number(value).isValid
    ),
  expiration_date: yup
    .string()
    .nullable()
    .required()
    .test('test-expiration-year', 'Debe ser una fecha válida', (value) => {
      return cardValidator.expirationDate(value).isValid
    }),
  security_code: yup
    .string()
    .nullable()
    .required()
    .test('test-number', 'Código de seguridad inválido', function (value) {
      const type = cardValidator.number(this.parent.card_number).card?.type
      return cardValidator.cvv(`${value}`, type === 'american-express' ? 4 : 3)
        .isValid
    }),
})

const automaticDebitFormSchema = yup.object({
  document_type: yup.string().required(),
  id_number: yup.string().required(),
  bank: yup.object().required(),
  account_type: yup.string().required(),
  account_number: yup.string().required(),
})

const pseFormSchema = yup.object({
  client_type: yup.string().nullable().required(),
  bank: yup.object().nullable().required(),
})

export const getValidationSchema = (paymentMethod, isCurrentMethodPayment) => {
  if (isCurrentMethodPayment) return null

  switch (paymentMethod) {
    case 'automatic_debit':
      return yup.object({ automatic_debit_form: automaticDebitFormSchema })
    case 'pse':
      return yup.object({ pse_form: pseFormSchema })
    default:
      return yup.object({ credit_card_form: creditCardFormSchema })
  }
}

export const getInitialValues = (isCurrentMethodPayment = false) => ({
  is_current_payment_method: isCurrentMethodPayment,
  payment_method: 'credit_card',
})

export const getTabsData = (
  subscription,
  isEditingPaymentMethod,
  currentPlanPayment
) => {
  const {
    payment_methods: availablePaymentMethods,
    subscription_payment_methods_info: currentPaymentMethodsInfo,
  } = subscription

  const {
    credit_card: showCreditCardMethod,
    automatic_debit: automaticDebitMethodAvailable,
    pse: showPseMethod,
  } = availablePaymentMethods || {}

  const currentPaymentMethods = getCurrentPaymentMethods(
    currentPaymentMethodsInfo
  )

  // In case of immediate payment required, shows automatic debit only when the bank account is already connected or is a current plan payment
  const showAutomaticDebitMethod =
    (currentPaymentMethods?.includes('automatic_debit') ||
      isEditingPaymentMethod ||
      currentPlanPayment) &&
    automaticDebitMethodAvailable

  const availablePaymentMethodsNumber = Object.keys(
    availablePaymentMethods || {}
  ).length

  const tabs = []

  // Show credit card when available or when no other payment methods are available
  if (showCreditCardMethod || availablePaymentMethodsNumber === 0)
    tabs.push({
      key: 'credit_card',
      label: (
        <Box display="flex" alignItems="center">
          <Icon
            name="smart-lock-card"
            sx={(theme) => ({
              marginRight: theme.spacing(0.5),
            })}
          />
          Tarjeta débito/crédito
        </Box>
      ),
    })

  if (showAutomaticDebitMethod)
    tabs.push({
      key: 'automatic_debit',
      label: (
        <Box display="flex" alignItems="center">
          <Icon
            name="refresh"
            sx={(theme) => ({
              marginRight: theme.spacing(0.5),
            })}
          />
          Débito automático
        </Box>
      ),
    })

  // Hide pse when editing current payment method
  if (showPseMethod && !isEditingPaymentMethod)
    tabs.push({
      key: 'pse',
      label: (
        <Box display="flex" alignItems="center">
          <Icon
            name="refund"
            sx={(theme) => ({
              marginRight: theme.spacing(0.5),
            })}
          />
          PSE
        </Box>
      ),
    })

  return tabs
}

export const paymentModality = {
  month: 'mensual',
  year: 'anual',
}

export const getInitialCopy = (paymentVariant, paymentMethods) => {
  if (paymentVariant === 'new_payment') {
    return 'Al completar este pago'
  }
  if (paymentVariant === 'payment_data_update') {
    return `Al ${paymentMethods?.length ? 'actualizar' : 'agregar'} tu método de pago`
  }
  if (paymentVariant === 'first_payment') {
    return 'Al pagar tu suscripción'
  }

  return 'Al completar esta acción'
}
