import { useNavigate } from 'react-router-dom'

import { Box, Typography, useTheme } from '@mui/material'

import useEndFreeModals from 'components/App/Premium/useEndFreeModals'
import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import { useUser } from 'components/App/UserContext/useUser'

import useDianMaintenance from 'utils/hooks/ElectronicPayroll/useDianMaintenance'
import useFeatureFlags from 'utils/hooks/useFeatureFlags'

import { usePeriod, usePeriodAPI } from '../helpers'
import ActionsCards from './ActionsCards'
import getActions from './helpers'

const PayrollActions = () => {
  const periodData = usePeriod()
  const periodAPIData = usePeriodAPI()
  const { distributor, company, user } = useUser()
  const { maintenance } = useDianMaintenance()
  const navigate = useNavigate()
  const themeInstance = useTheme()

  const { showPremiumFeatures, openPremiumFeatureModal } = usePremiumFeature()
  const endFreeFuntionalitiesModals = useEndFreeModals()

  const { holisticPayrollMVP, flagsReady } = useFeatureFlags({
    flags: ['holisticPayrollMVP'],
    trackingMode: 'attributes',
    attributes: {
      userEmail: user.email,
    },
  })

  const { electronic_period_id: electronicPeriodId } = company

  const actions = getActions({
    periodData,
    periodAPIData,
    electronicPeriodId,
    maintenance,
    distributor,
    themeInstance,
    showPremiumFeatures,
    openPremiumFeatureModal,
    navigate,
    endFreeFuntionalitiesModals,
    holisticPayrollMVP,
    flagsReady,
  })

  return (
    <Box sx={{ gridColumn: '1 / -1' }}>
      <Typography variant="h4">Acciones que puedes realizar</Typography>
      <Box
        sx={(theme) => ({
          marginTop: theme.spacing(4),
          marginBottom: theme.spacing(7),
          display: 'grid',
          gridColumn: '1 / -1',
          columnGap: theme.spacing(3),
          rowGap: theme.spacing(3),
          gridTemplateColumns: 'repeat(4, 1fr)',
          gridAutoRows: 'max-content',
          [theme.breakpoints.up('tablet')]: {
            gridTemplateColumns: 'repeat(6, 1fr)',
            columnGap: theme.spacing(4),
          },
          [theme.breakpoints.up('desktop')]: {
            gridTemplateColumns: 'repeat(12, 1fr)',
          },
        })}
      >
        {actions.map((action) => {
          if (
            action.options.length === 0 ||
            action.options.every((item) => item.hidden === true)
          )
            return null

          return (
            <Box
              key={action.id}
              sx={(theme) => ({
                gridColumn: '1 / -1',
                [theme.breakpoints.up('tablet')]: {
                  gridColumn: 'span 3',
                },
                [theme.breakpoints.up('md')]: {
                  gridColumn: 'span 2',
                },
                [theme.breakpoints.up('desktop')]: {
                  gridColumn: 'span 6',
                },
                [theme.breakpoints.up('xdesktop')]: {
                  gridColumn: 'span 4',
                },
              })}
            >
              <ActionsCards
                action={action}
                expandDataCy={`expand-${action.id}`}
              />
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export default PayrollActions
