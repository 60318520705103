import { useUser } from 'components/App/UserContext/useUser'

const useSubscription = () => {
  const { subscription } = useUser()
  const additionalWorkersInfo = subscription?.additional_workers_info
  const paidWorkers = subscription?.paid_workers

  const isPaymentExoneratedCompany =
    subscription.payment_category === 'exonerated_payment'

  return {
    additionalWorkersInfo,
    paidWorkers,
    subscription,
    isPaymentExoneratedCompany,
  }
}

export default useSubscription
