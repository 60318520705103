import { parseDate } from '@internationalized/date'
import { useDateFormatter } from '@react-aria/i18n'
import { createRef, useMemo } from 'react'

export const parseValueWithLeadingZeros = (value) => {
  return `${+value <= 9 ? 0 : ''}${+value}`
}

export const parseDateSegments = (date) => {
  return `${date.year}-${parseValueWithLeadingZeros(
    date.month
  )}-${parseValueWithLeadingZeros(date.day)}`
}

export const useCalendarYears = ({ state, minValue, maxValue }) => {
  const { timeZone } = state
  const formatter = useDateFormatter({
    year: 'numeric',
    timeZone,
  })
  const startDate = parseDate(`1900-01-01`)
  const years = useMemo(() => {
    let nextYears = []

    for (let i = 0; i < 200; i += 1) {
      const date = startDate.add({ years: i })

      nextYears.push({
        value: date,
        label: formatter.format(date.toDate(timeZone)),
      })
    }

    // Add only years between minValue and maxValue

    if (minValue?.year) {
      nextYears = nextYears.filter(
        (yearItem) => yearItem?.value?.year >= minValue?.year
      )
    }

    if (maxValue?.year) {
      nextYears = nextYears.filter(
        (yearItem) => yearItem?.value?.year <= maxValue?.year
      )
    }

    return nextYears
  }, [formatter, maxValue?.year, minValue?.year, startDate, timeZone])
  const yearsRefs = useMemo(
    () =>
      years.reduce((acc, value) => {
        acc[value.label] = createRef()
        return acc
      }, {}),
    [years]
  )

  return {
    years,
    yearsRefs,
    hasAvailableYears: years.length > 1,
  }
}

export const useCalendarMonths = ({ state, year, minValue, maxValue }) => {
  const { timeZone } = state
  const formatter = useDateFormatter({
    month: 'short',
    timeZone,
  })
  const startDate = parseDate(`${year}-01-01`)
  const months = useMemo(() => {
    const nextMonths = []

    for (let i = 0; i < 12; i += 1) {
      const date = startDate.add({ months: i })

      nextMonths.push({
        value: date,
        label: formatter.format(date.toDate(timeZone)),
      })
    }

    if (minValue?.year === year) {
      nextMonths.splice(0, minValue.month - 1)
    }

    if (maxValue?.year === year) {
      nextMonths.splice(maxValue.month)
    }

    return nextMonths
  }, [formatter, startDate, timeZone, year, minValue, maxValue])

  return {
    months,
  }
}
