import { useLocation, useNavigate } from 'react-router-dom'

import useSubscription from 'components/Subscription/Atoms/useSubscription'

import {
  getUserRoleSection,
  isAdminAndWorker,
  isAlegraClaroDist,
  isOrganizer,
  isWorker,
} from 'utils/auth'
import { hasSelectedCompany } from 'utils/company'

import {
  COMPANY_INDEX,
  DASHBOARD,
  LOGOUT,
  USER_PROFILE,
  WORKER_PROFILE_SHOW,
} from 'config/routes'

import { useSupportMenuContext } from '../SupportMenuContext'
import { removeCurrentChat } from '../UserContext/loadChatScript'
import { useUser } from '../UserContext/useUser'

const useUserMenuOptions = (onClosePopup, xsDown, userHashCompanyName) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { subscription } = useSubscription()
  const { showChat, setShowChat } = useSupportMenuContext()

  const { updateUser, clearFetchCompany } = useUser()

  const options = []

  const getSwitchMenuItemConf = () => {
    const role = getUserRoleSection(pathname)

    const [message, url] =
      role === 'worker'
        ? ['empresa', DASHBOARD]
        : ['personal', WORKER_PROFILE_SHOW()]

    return { message, url }
  }
  const { message, url } = getSwitchMenuItemConf()

  const openCompaniesView = () => {
    onClosePopup()
    clearFetchCompany()
    navigate(COMPANY_INDEX())
  }

  const handleAdminAndWorker = () => {
    if (showChat) setShowChat(false)
    if (window?.$zoho?.salesiq) {
      window.$zoho.salesiq.reset()
      removeCurrentChat()
    }
    onClosePopup()
    updateUser({
      role: isWorker() ? 'admin' : 'worker',
    })
    localStorage.setItem('comeFromCompany', true)
    navigate(url, { replace: true })
  }

  const logOut = () => {
    onClosePopup()
    navigate(LOGOUT, { replace: true })
  }

  const openProfileView = () => {
    onClosePopup()
    navigate(USER_PROFILE())
  }

  if (
    !isAlegraClaroDist() &&
    hasSelectedCompany() &&
    subscription?.utm_campaign !== 'Aleluya10mil'
  ) {
    options.push({
      id: 'show_profile',
      name: 'Cuenta',
      onClick: openProfileView,
    })
  }

  if (
    !isWorker() &&
    !isOrganizer() &&
    subscription?.utm_campaign !== 'Aleluya10mil'
  ) {
    options.push({
      id: 'show_companies',
      name: 'Ver empresas',
      onClick: openCompaniesView,
    })
  }

  if (isAdminAndWorker()) {
    options.push({
      id: 'admin_worker',
      name: `Perfil ${message}`,
      onClick: handleAdminAndWorker,
    })
  }

  if (!isAlegraClaroDist() || isOrganizer()) {
    options.push({
      id: 'logout',
      name: 'Cerrar sesión',
      onClick: logOut,
    })
  }

  if (xsDown) {
    options.unshift({
      id: 'show_company_name',
      name: <b>{userHashCompanyName()}</b>,
      onClick: () => {},
    })
  }

  return options
}

export default useUserMenuOptions
